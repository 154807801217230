import { useState } from 'react'
import '../css/background_index.css'
import { Link } from 'react-router-dom'
import {isString, useFormik} from 'formik'
import '../css/reset.css'
import header from '../css/header.module.css'
import main from '../css/main.module.css'
import '../css/offer_form.css'
import * as Yup from 'yup';
import InputMask from 'react-text-mask'
import axios from 'axios'
import { useEffect } from 'react'

const formSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'В поле должно быть не меньше 3 символов')
    .max(40, 'В поле должно быть не меньше 40 символов')
    .required('Обязательное поле'),

  phone: Yup.string()
    .transform(value => value.replace(/[^\d]/g, ''))
    .required('Обязательное поле')
    .min(11, 'Неполный ввод номера телефона')
});


const MainLayout = (props) => {
    const [openForm, setOpenForm] = useState(false)
    const activeForm = () => {        
        setOpenForm(!openForm)
    }
    const [aspectStyle, setAspectStyle] = useState('')
    const docElem = document.documentElement

    const styleResize = () => {
        let aspect = docElem.clientWidth/docElem.clientHeight        
        if (aspect<1.5) {
            setAspectStyle('extraNarrow')
        } else if (aspect<1.72) {
            setAspectStyle('narrow')
        } else if (aspect<1.8) {
            setAspectStyle('wide')
        } else {
            setAspectStyle('extraWide')
        }
    }
    if (aspectStyle == '') {
        styleResize()
    }

    useEffect(() => {
        window.addEventListener('resize', styleResize)
        return () => window.removeEventListener('resize', styleResize) 
    }, [])

    return (
        <div className={'page '+aspectStyle}> 
            <Header activeForm={activeForm} />
                <main>
                    {props.children}
                </main>
            {openForm==true && <OfferForm activeForm={activeForm} />}
        </div>
    )
}

const Header = (props) => {
    const getHours = (start, end) => {
        const now = new Date()
        const differenceByMsk = (now.getTimezoneOffset()/60)+3
        let [startWork, endWork] = [start, end]
        startWork = now.getHours(now.setHours(startWork-differenceByMsk))
        endWork = now.getHours(now.setHours(endWork-differenceByMsk))
        return `${startWork.length<2 ? '0'+startWork:startWork}:00-
            ${endWork.length<2 ? '0'+endWork:endWork}:00`
    }
    const workHours = getHours(6, 15)

    return (
        <header className="container">
            <nav>
                <ul>
                    <li className={header.logo}>
                        <Link to="/">
                            <img className={header.firmNameLogo} src="images/firm_logo.svg" />
                            <img className={header.agencyLogo} src="images/agency.svg" />
                        </Link>
                    </li>
                    <li className={header.phone}>
                        <div className={header.numberPhone}><p>+7 (3812) </p><p>308-136</p></div>
                        <p className={header.requestPhone}>ПН-ПТ <br />
                            {workHours}</p>
                    </li>
                    <li>
                        <button 
                            className={header.callButton}
                            id="callButton"
                            onClick={() => props.activeForm()}
                        >Позвоним бесплатно</button>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

const OfferForm = (props) => {
    const [isSend, isSendSet] = useState(false);
    const [sendError, setSendError] = useState(false);
    const formik = useFormik({
        initialValues: {
            name: '',
            phone: ''
        },
        onSubmit: values => {
            axios({
                method: "get",
                url: `https://metrika.ferma-site.ru/index.php?module=API&method=Leads.addReport&format=json&who=${values.name}&phone=${values.phone}`,
            }).then(res => {
                res.status == '200' ? isSendSet(true): setSendError(true)})
            .catch((e) => {
                setSendError(true)
            })
        },
        validationSchema: formSchema
    })

    return ( 
        <div id="backgroundDark" className="container">
            {sendError==false ? isSend==false ?
                <div id='offerForm'>
                    <button id="exitButton" onClick={() => {
                        props.activeForm()
                        setSendError(false)
                    }}>
                        <img src="images/cross.svg" />
                    </button>
                    <form onSubmit={formik.handleSubmit}>
                        <h2>Оставьте свои данные и мы свяжемся с вами для бесплатной консультации</h2>
                        <div className="nameBlock">
                            <input 
                                value={formik.values.name} 
                                onChange={formik.handleChange} 
                                type="text" 
                                name="name" 
                                id="name" 
                                placeholder="Представьтесь" 
                                className={formik.errors.name ? 'errorInput' : ''}
                            />
                            {formik.errors.name ? (
                                <div className="error"><div className='errorTriangle'></div>{formik.errors.name}</div>
                            ) : null}
                        </div>
                        <div className="phoneBlock">
                            <InputMask 
                                mask={['+', '7', '(', /\d/, /\d/, /\d/, ')', '-',
                                    /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/
                                ]} 
                                onChange={formik.handleChange} 
                                value={formik.values.phone} 
                                type="text" 
                                name="phone"
                                id="phone"
                                placeholder="Номер вашего телефона" 
                                className={formik.errors.phone ? 'errorInput' : ''}
                            />
                            {formik.errors.phone ? (
                                <div className="error"><div className='errorTriangle'></div>{formik.errors.phone}</div>
                            ) : null} 
                        </div>    
                        <button className={main.formButton} id="formButton" type="submit">Заказать звонок</button>
                        <h3 className="confidential">Нажимая кнопку «Получить», вы соглашаетесь с <Link onClick={() => props.activeForm()} to="/privacy_policy">«Политикой конфиденциальности»</Link></h3>
                    </form>
                </div>
            : <SendComponent activeForm={props.activeForm} setSendError={setSendError} />
            : <h2 className="sendMessage">При отправке данных произошла ошибка. Попробуйте позже</h2>}     
            <div id="exitBackground" onClick={() => {
                props.activeForm()
                setSendError(false)
            }}></div>
        </div>)
}

const SendComponent = (props) => {
    return (
        <div id="sendMessage">
            <button id="exitButton" onClick={() => {
                    props.activeForm()
                    props.setSendError(false)
                }}>
                    <img src="images/cross.svg" />
                </button>
                <div className="sendImages">
                    <img src="images/ok_circle.svg" />
                    <img id="littleStar-bottom" src="images/star.svg" />
                    <img id="littleStar-top" src="images/star.svg" />
                    <img id="bigStar" src="images/star.svg" />
                </div>
            <h2>
                <p>Спасибо, что отправили заявку!</p>
                <p>Мы с вами свяжемся в ближайшее время</p>
            </h2>
        </div>
    )
}

export default MainLayout
