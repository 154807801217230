import  {Routes, Route} from 'react-router-dom';
import IndexPage from './pages/IndexPage';
import PrivacyPage from './pages/PrivacyPage';

function App() {
  return (
    
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/privacy_policy" element={<PrivacyPage />} />
      </Routes>

  );
}

export default App;
